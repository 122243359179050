// Recibo Pagamento

.body-print {
    background-color: #fff;
}

.nsb-endereco {
    color: #6a6b80;
    line-height: 22px;
    font-style: normal;
    font-weight: normal;
}

.main-print {
    background-color: red;
}

.logo-center {
    margin: 0 auto;
}

div.separator {
    list-style: none;
    padding: 0;
    left: 20rem;
    width: 28%;
    margin: 0 auto;
}

div.separatorPage {
    list-style: none;
    padding: 0;
    left: 20rem;
    width: 100%;
    margin: 0 auto;
}

div.separator hr {
    border-bottom: 1px solid #000;
}

div.separatorPage hr {
    border-bottom: 1px solid #000;
}

.button-print {
    background-color: #2b293e;
    border: 2px solid #2b293e;
    border-radius: 3px;
    padding: 10px 40px;
}

.color-text-table-print {
    color: #000;
    font-weight: bold;
}

.dataRowPrint {
    // padding: 12px;
    margin: 18px 0px;
    background-color: #fff;
    // -webkit-border-radius: 5px;
    // -moz-border-radius: 5px;
    // border: 1px solid #9495AD;
    // border-radius: 3px;
}

.dataRowPrint .item {
    float: left;
    min-height: 1px;
    overflow: hidden;
    color: #000;
}

@media print {
    .button {
        display: none;
    }
}

//Cerimonial

div.separatorProvider {
    list-style: none;
    padding-left: 20px;
    left: 20rem;
    width: 100%;
    margin: 0 auto;
}

div.separatorProvider hr {
    border-bottom: 1px solid #666666;
}
