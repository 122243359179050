@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:regular,bold,italic&subset=latin,latin-ext");

body,
html {
  font-family: "Nunito Sans", sans-serif;
}

body {
  background-color: #faf9fc;
}

//Text
.lineThrough {
  text-decoration: line-through;
}

//Cores

$color-main-blue: rgba(43, 41, 62, 1);
$color-second-blue: rgba(63, 76, 112, 1);
$color-sidebar-icon-blue: #5c6384;
$color-main-salmon: rgba(248, 168, 139, 1);
$color-second-salmon: rgba(255, 236, 229, 1);
$color-main-green: rgba(88, 215, 199, 1);
$color-main-red: rgba(255, 115, 132, 1);
$color-darker-gray: rgba(34, 34, 34, 1);
$color-dark-gray: rgba(106, 107, 128, 1);
$color-medium-gray: rgba(148, 149, 173, 1);
$color-light-grey: rgba(225, 226, 239, 1);
$color-bg-gray: rgba(250, 249, 252, 1);
$color-white: rgba(255, 255, 255, 1);
$color-original-blue: rgba(0, 59, 105, 1);
$color-medium-blue: rgba(129, 147, 197, 1);
$background-linear-gradient: linear-gradient(180deg, #3f4c70 0%, #7584ad 100%);

.color-main-salmon {
  color: $color-main-salmon;
}

.text-blue-footer {
  color: $color-second-blue;
}

//Forms

.validationError {
  border-color: $color-main-red;
  color: $color-main-red !important;
}

.errorSumary {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 0px;
  list-style: none;

  li {
    color: $color-main-red !important;
  }
}

.errorText {
  color: $color-main-red !important;
}

.hasLink {
  color: $color-main-red !important;
}

select {
  background: url("../img/brand/expand_more.svg") 100% / 18px no-repeat;
  background-origin: content-box;
  -webkit-appearance: none;
  background-blend-mode: difference;
}

//dataList

.dataList {
  width: 100%;

  button.edit {
    background-color: #e1e2ef;
    color: #6a6b80;
    padding: 5px 20px;
    cursor: pointer;
  }

  button.exc {
    background-color: #ff6678;
    color: #fff;
    padding: 5px 20px;
    cursor: pointer;
  }

  button.participants {
    background-color: #f8a88b;
    color: #fff;
    padding: 5px 20px;
    cursor: pointer;
  }

  .clear {
    clear: both;
  }

  .collumns {
    width: 100%;
  }

  .collumns div {
    float: left;
    min-height: 1px;
  }

  .items {
    width: 100%;
  }

  .dataRow {
    padding: 12px;
    margin: 15px 0px;
    background-color: $color-white;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(199, 199, 199, 1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(199, 199, 199, 1);
    box-shadow: 0px 1px 5px 0px rgba(199, 199, 199, 1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .dataRow .item {
    float: left;
    min-height: 1px;
    // overflow: hidden;
    color: #000;
  }
}

//Loading

@keyframes blinking {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.3;
  }
}

.loadingSelect {
  animation-name: blinking;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  cursor: wait;
}

.tam-load {
  height: 10px;
}

// Sidebar.jsx

.menuIconColor {
  color: $color-sidebar-icon-blue;
}

.menuActiveItem {
  background-color: rgba(255, 51, 0, 0.096);
}

.logout:hover {
  font-weight: bold;
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 3.2rem;
    margin-top: 10px;
  }
}

.user-name {
  white-space: nowrap;
}

//Auth Footer

@media (min-height: 650px) {
  footer.auth-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

//Admin Footer

.minHeightFooter {
  min-height: 640px;
}

// Login
.ft-16 {
  font-size: 16px;
}

.bg-gradient-login {
  background: $background-linear-gradient;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.login-logo {
  margin-top: 70px;
  height: 195px;
}

.botao-laranja {
  background: $color-main-salmon;
  color: #fff;
  border: none;
}

.botao-laranja:hover {
  background: $color-main-salmon;
  color: #fff;
}

.cor-input {
  color: $color-sidebar-icon-blue;
}

.login-link-form {
  color: #fff;
}

.login-link-form:hover {
  color: #fff;
}

.photo-avatar {
  height: 120%;
  width: 120% !important;
  object-fit: cover;
}

//Home

.title {
  font-size: 39.06px;
}

.subtitle {
  color: $color-medium-gray;
  margin: -12px 0px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  font-size: 20px;
}

.rectangle-orange {
  position: absolute;
  width: 70px;
  height: 4px;
  left: 12px;
  top: 40px;
}

.card-body {
  height: 220px;
  border: 2px soli;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.card-text {
  color: #000;
}

.card-home,
.card-hover {
  position: absolute;
  top: 42px;
}

.card-hover {
  display: none;
}

.card-stats:hover {
  .card-home {
    display: none;
  }

  .card-hover {
    display: block;
  }

  .card-body {
    background-color: $color-second-blue;
  }

  .card-text {
    color: #fff !important;
  }
}

.card-title {
  position: absolute;
  top: 147px;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  color: #000;
}

//Pessoas

.cursor-pointer {
  cursor: pointer;
}

.label-checkbox {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
}

.label-checkbox-fornecedores {
  display: table;
  table-layout: fixed;
  position: relative;
  bottom: 2px;
}

.m-ico-checkbox {
  margin-top: -27px;
  margin-right: -19px;
  font-size: 11px;
}

.text-person {
  font-style: normal;
  font-weight: bold;
  font-size: 31.25px;
  line-height: 43px;
  color: #222222;
}

.text-deceased {
  font-style: normal;
  font-weight: bold;
  font-size: 31.25px;
  line-height: 43px;
  color: lightslategray;
}

.btn-person-search {
  padding: 10px 40px;
  border: 2px solid #f8a88b;
  background-color: $color-main-salmon;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.btn-person-search:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.btn-person-clean {
  padding: 10px 40px;
  border: 2px solid #9495ad;
  background-color: #f8f9fe;
  color: #9495ad;
  border-radius: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.btn-person-clean:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.msgVisiblePerson {
  display: none;
}

.msgVisibleCourse {
  display: none;
}

.msgVisibleCelebrant {
  display: none;
}

.msgVisibleProvider {
  display: none;
}

.msgVisibleEvent {
  display: none;
}

.msgVisiblePayment {
  display: none;
}

.color-text-table {
  color: $color-medium-gray;
  font-weight: bold;
}

.text-select {
  font-size: 0.875rem;
  letter-spacing: normal;
}

.tp-1 {
  top: 1px;
}

.tp-3 {
  top: 3px;
}

.tp-4 {
  top: 4px;
}

.ft-16 {
  font-size: 20px;
  font-weight: bold;
}

.text-modal {
  line-height: 1;
}

.title-people {
  text-align: center;
}

.button-yes {
  background-color: #58d7c7;
  color: #fff;
  padding: 10px 40px;
}

.button-no {
  background-color: #e1e2ef;
  color: #6a6b80;
  padding: 10px 40px;
}

.button-add-people {
  background-color: #3f4c70;
  border: 2px solid #3f4c70;
  border-radius: 5px;
  padding: 10px 40px;
}

.x {
  cursor: pointer;
}

.modal-form {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.btn-excel {
  color: $color-medium-gray;
  border: 2px solid #e1e2ef;
  padding: 10px 40px;
  border-radius: 3px;
}

// Wizard

$line: #e1e2ef;
$notComplete: #8193c5;
$inProgress: #f8a88b;
$complete: #58d7c7;

.notAllowedText {
  color: #9495ad;
  //border: 6px solid $line;
}

.notAllowedBall {
  cursor: not-allowed;
  background-color: $line !important;
  border: 6px solid $line !important;
}

.wizard-progress {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;

  .title-wizard {
    top: 65px;
    position: relative;
    left: 5px;
  }

  .step {
    display: table-cell;
    text-align: center;
    vertical-align: top;
    overflow: visible;
    position: relative;
    line-height: 1;
    color: black;
    font-weight: bold;

    &:not(:last-child):before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 37px;
      background-color: $line;
      height: 3px;
      width: 100%;
    }

    .node {
      display: inline-block;
      border: 6px solid $notComplete;
      background-color: $notComplete;
      border-radius: 20px;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 17px;
      left: 50%;
      margin-left: -18px;
    }

    &.complete {
      &:before {
        background-color: $line;
      }

      .node {
        border-color: $complete;
        background-color: $complete;
      }
    }

    &.in-progress {
      &:before {
        background: $line;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$inProgress}', endColorstr='#{$inProgress}', GradientType=1);
      }

      .node {
        border-color: $inProgress;
        background-color: $inProgress;
      }
    }
  }
}

//Page

.page-item.active .page-link {
  background: #3f4c70;
  border: none;
  border-radius: 3px !important;
}

.page-item .page-link,
.page-item span {
  border: none;
  border-radius: 3px !important;
}

//Informações Pesssoais

.back-upload {
  background: #e1e2ef;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  width: 253px;
  height: 323px;
}

.btn-upload-img {
  padding: 10px 40px;
  border: 2px solid $color-main-salmon;
  border-radius: 3px;
  background: $color-main-salmon;
  color: #fff;
  cursor: pointer;
}

.btn-upload-img:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.button-capture-img {
  background-color: #58d7c7;
  color: #fff;
  padding: 10px 40px;
  margin-top: 7px;
}

.webcam {
  display: block;
  margin: 0 auto;
}

.button-select-img {
  background-color: #e1e2ef;
  color: #6a6b80;
  padding: 10px 40px;
  margin-top: 7px;
}

.btn-info-person-exc {
  padding: 10px 40px;
  border: 2px solid #ff7384;
  color: #ff7384;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  background: #f8f9fe;
  cursor: pointer;
}

.btn-info-person-exc:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.btn-info-person {
  padding: 10px 40px;
  border: 2px solid #58d7c7;
  background-color: #58d7c7;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.color-btn-info {
  color: #58d7c7;
}

.btn-info-person-obito {
  padding: 10px 40px;
  border: 2px solid #3f4c70;
  color: #3f4c70;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  background: #f8f9fe;
  cursor: pointer;
}

.btn-info-person-obito:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.btn-info-person:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.btn-info-person-cancel {
  padding: 10px 40px;
  border: 2px solid #9495ad;
  background-color: #f8f9fe;
  color: #9495ad;
  border-radius: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.btn-info-person-cancel:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.btn-group-nsb {
  color: #212529;
  background-color: #f7fafc;
  border-color: #f7fafc;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  width: 112px;
  //z-index: 1;
}

.btn-group-nsb:hover {
  color: #212529;
  background-color: #ffece5;
  border-color: 1px solid #ffece5;
  border: 1px solid #f8a88b;
  border-radius: 0px 3px 3px 0px;
}

.btn-group-nsb:focus,
.btn-group-nsb.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(247, 250, 252, 0.5);
}

.btn-group-nsb.disabled,
.btn-group-nsb:disabled {
  color: #212529;
  background-color: #f7fafc;
  border-color: #f7fafc;
}

.btn-group-nsb:not(:disabled):not(.disabled):active,
.btn-group-nsb:not(:disabled):not(.disabled).active,
.show>.btn-group-nsb.dropdown-toggle {
  color: #212529;
  background-color: #ffece5;
  border-color: 1px solid #ffece5;
  border: 1px solid #f8a88b;
  border-radius: 0px 3px 3px 0px;
}

.btn-group-nsb:not(:disabled):not(.disabled):active:focus,
.btn-group-nsb:not(:disabled):not(.disabled).active:focus,
.show>.btn-group-nsb.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(247, 250, 252, 0.5);
}

.isFilterActive {
  border: 2px solid #3f4c70;
}

.input-img-hidden {
  display: none;
}

.imgUploaded {
  width: 323px;
  max-width: 100%;
  height: 323px;
  object-fit: cover;
}

.ReactModal__Overlay {
  z-index: 999;
}

//Family

.imgUploadedFamily {
  max-height: 323px;
  border-radius: 50%;
}

.m-enter {
  margin: -24px 0px 24px -19px;
}

.m-enter-not-photo {
  margin: -12px 0px 24px -150px;
}

.invisibel {
  position: absolute;
  width: 95%;
  top: 37%;
  background-color: transparent !important;
}

.c-transp {
  color: transparent !important;
  caret-color: #000;
}

.imgSugested {
  height: 58px;
  width: 58px;
  border-radius: 50%;
  margin-top: 5px;
  object-fit: cover;
}

.ws-nowrap {
  white-space: nowrap;
}

.input-Family {
  position: absolute;
  margin-top: 43px;
  width: 95%;
}

.mt-input-not-photo {
  margin-top: 5px;
}

.btn-family-insert {
  padding: 5px 20px;
  background: #e1e2ef;
  border: 2px solid #e1e2ef;
  border-radius: 3px;
  color: #6a6b80;
  font-style: normal;
  font-size: 12.9px;
  line-height: 18px;
  margin-top: 14px;
}

.btn-family-delete {
  padding: 5px 20px;
  background: #ff6678;
  border: 2px solid #ff7384;
  border-radius: 3px;
  color: #fff;
  font-style: normal;
  font-size: 12.9px;
  line-height: 18px;
  margin-top: 14px;
}

.div-family-btn {
  margin-top: 29px;
}

.div-family-btn-photo {
  margin-top: 36px;
}

.imgFamily {
  display: flex;
}

.text-family {
  font-size: 14.9px;
  line-height: 18px;
  color: #222;
}

.text-family-two {
  font-size: 17px;
  line-height: 18px;
  color: #222;
}

.button-add-child {
  padding: 10px 40px;
  background: #f8a88b;
  border: 2px solid #f8a88b;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}

//Cadastros Auxiliares

.button-add {
  padding: 10px 40px;
  background: #f8a88b;
  border: 2px solid #f8a88b;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}

.btn-come-back {
  color: $color-medium-gray;
  border: 2px solid #e1e2ef;
  padding: 10px 40px;
  border-radius: 3px;
}

.card-register {
  height: 97px;
  border: 2px soli;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.card-title-register {
  position: absolute;
  top: 35px;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  color: #000;
}

.input-checkbox {
  width: 20px;

  &:hover+label:before {
    background: #3f4c70;
  }

  &:checked+label:before {
    background: #3f4c70 !important;
    border: #3f4c70 !important;
  }
}

.dropdown-menu-arrow {
  overflow-y: scroll;
  max-height: 500px;
}

//Eventos

.engaged-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.engaged-mt {
  margin-top: 0.4rem;
}

.mt-inputSearch {
  margin-top: -5px;
}

.pl-47 {
  padding-left: 47px;
}

.ml-20 {
  margin-left: 20rem;
}

.w-15 {
  min-width: 11rem;
  max-width: 16rem;
}

.w-20 {
  min-width: 20rem;
}

.w-30 {
  min-width: 30rem;
}

.w-34 {
  min-width: 34rem;
}

.h-3 {
  height: 3rem;
}

.default-select {
  padding: 11px 13px 12px 12px;
  box-shadow: none !important;
  cursor: auto !important;
  background-color: #fff !important;
  border: 1px solid #cad1d7;
  background: url("../img/brand/expand_more.svg") 100% / 18px no-repeat;
  background-origin: content-box;
  -webkit-appearance: none;
  background-blend-mode: difference;
}

.default-select:hover {
  box-shadow: none !important;
  transform: none !important;
  border: 1px solid #cad1d7 !important;
  background-color: #fff !important;
}

.filterActive {
  border: 2px solid #3f4c70;
}

.filterActive:hover {
  border: 2px solid #3f4c70 !important;
}

.mb-inputSearch {
  margin-bottom: 58px;
}

// Impressão de ficha resumida

.container-impressao {
  display: flex;
  flex-direction: column;
  align-items: center;

  .max-width {
    width: 500px;
    height: 275px;
  }

  .first-item {
    padding-top: 15px;
  }

  .item {
    margin-top: 3px;
  }

  .itemPayment {
    // width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .itemPaymentTeste {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .itemValor {
    // width: 380px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .itemDate {
    // width: 360px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.ellipse-list {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.padding-right-print {
  padding-right: 6px;
}