$d_mainPadding: 40px;
$d_boxSize: 70px;
$d_backGroundColor: #fff;
$d_titlePadding: 20px;
$d_tittleBottomPadding: 40px;

.dating {
    padding: $d_mainPadding;
    display: inline-block;
    background-color: $d_backGroundColor;

    .d_selected {
        background: #f8a88b;
        color: #fff !important;
        -webkit-border-radius: ($d_boxSize / 2);
        -moz-border-radius: ($d_boxSize / 2);
        border-radius: ($d_boxSize / 2);
    }

    .d_boop {
        margin-top: -(10px + ($d_boxSize / 2));
        font-size: 7px;
        color: #f8a88b;
    }

    .d_title {
        text-align: center;
        font-size: 25px;
        color: #000;
        font-weight: bold;
        padding-left: $d_titlePadding;
        padding-right: $d_titlePadding;
        padding-bottom: $d_tittleBottomPadding;

        .d_pointer {
            color: #f8a88b;
            cursor: pointer;
        }

        .d_leftPointer {
            display: block;
            float: left;
        }

        .d_rightPointer {
            display: block;
            float: right;
        }

        .d_month {
            display: inline-block;
        }
    }

    .d_header {
        div {
            color: #9495ad;
            font-size: 20px;
        }
    }

    .d_days {
        clear: both;
    }

    .d_clear {
        clear: both;
    }

    .d_baseBox {
        color: #222222;
        text-align: center;
        line-height: $d_boxSize;
        width: $d_boxSize;
        height: $d_boxSize;
        float: left;
        display: block;
        overflow: hidden;
        font-size: 20px;
        cursor: default;
    }

    .d_fadded {
        color: #e1e2ef;
    }

    .d_date {
        cursor: pointer;
    }
}
